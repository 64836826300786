import React from 'react';
import classes from './Footer.module.css';
import LogoLight from '../../assets/images/LogoLight.svg';
import PoweredBy from '../../assets/images/PoweredBy.png';
import FooterNav from '../Menus/FooterNav/FooterNav';

const footer = (props) => (
  <footer>
    <div className={classes.FooterWrapper}>
      <div className={classes.Logos}>
        <div><img src={LogoLight} /></div>
        <div><img src={PoweredBy} /></div>
      </div>
      <div className={classes.Contacts}>
        <FooterNav />
      </div>
    </div>
  </footer>
);

export default footer;
