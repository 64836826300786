import React, { Component } from 'react';
import emailjs from 'emailjs-com';

import Button from '../../UI/Button/Button';
import Input from '../../UI/Input/Input';
import Modal from '../../UI/Modal/Modal';
import ModalInfo from '../../UI/ModalInfo/ModalInfo'

class ContactData extends Component {
  constructor(props) {
    super(props);
    this.state = {
      contactForm: {
        name: {
          elementType: 'input',
          elementConfig: {
            type: 'text',
            placeholder: '',
            id: 'name',
            label: 'Name*',
          },
          value: '',
          validation: {
            required: true,
          },
          valid: false,
          touched: false,
          onFocus: false,
        },
        email: {
          elementType: 'input',
          elementConfig: {
            type: 'email',
            placeholder: '',
            id: 'email',
            label: 'E-mail*',
          },
          value: '',
          validation: {
            required: true,
            emailPattern: '/^([w.%+-]+)@([w-]+.)+([w]{2,})$/i',
          },
          valid: false,
          touched: false,
          onFocus: false,
        },
        phone: {
          elementType: 'input',
          elementConfig: {
            type: 'text',
            placeholder: '',
            id: 'phone',
            label: 'Telephone*',
          },
          value: '',
          validation: {
            required: true,
          },
          valid: false,
          touched: false,
          onFocus: false,
        },
        monthlyBudget: {
          elementType: 'select',
          elementConfig: {
            options: [
              { value: 'none', displayValue: 'Monthly budget*' },
              { value: '10K', displayValue: '10К EUR' },
              { value: '10К-100К', displayValue: '10К-100К EUR' },
              { value: '100K-500K', displayValue: '100K-500K EUR' },
              { value: '500K+', displayValue: '500K+ EUR' },
            ],
            placeholder: '',
            id: 'monthlyBudget',
            label: 'Monthly budget*',
          },
          value: 'none',
          valid: true,
          validation: {
            required: true,
            selectValue: 'none',
          },
          onFocus: false,
        },
        website: {
          elementType: 'input',
          elementConfig: {
            type: 'text',
            placeholder: '',
            id: 'website',
            label: 'Website*',
          },
          value: '',
          validation: {
            required: true,
          },
          valid: false,
          touched: false,
          onFocus: false,
        },
        inquiry: {
          elementType: 'input',
          elementConfig: {
            type: 'text',
            placeholder: '',
            id: 'inquiry',
            label: 'Inquiry*',
          },
          value: '',
          validation: {
            required: true,
          },
          valid: false,
          touched: false,
          onFocus: false,
        },
      },
      formIsValid: false,
      modal: false,
    };
    this.baseState = this.state;
  }

  submitHandler = (event) => {
    event.preventDefault();

    const formData = {};
    for (let formElementIdentifier in this.state.contactForm) {
      formData[formElementIdentifier] = this.state.contactForm[
        formElementIdentifier
      ].value;
    }
    let templateParams = {
      from_name:
        this.state.contactForm.name.value +
        ' (' +
        this.state.contactForm.email.value +
        ')',
      to_name: 'Zmatic',
      subject: 'New Submit',
      email: this.state.contactForm.email.value,
      name: this.state.contactForm.name.value,
      phone: this.state.contactForm.phone.value,
      inquiry: this.state.contactForm.inquiry.value,
      monthly_budget: this.state.contactForm.monthlyBudget.value,
      website: this.state.contactForm.website.value,
    };

    emailjs.send(
      'smtp_server',
      'template_NFVBNfog',
      templateParams,
      'user_OqeD5BEdwnbz7TWfeplTV'
    );

    this.setState({ modal: true });
  };

  resetForm() {
    this.setState(this.baseState);
  }

  checkValidity(value, rules) {
    let isValid = true;

    if (rules.required) {
      isValid = value.trim() != '' && isValid;
    }

    if (rules.selectValue) {
      isValid = value !== rules.selectValue && isValid;
    }

    if (rules.emailPattern) {
      isValid = value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i) && isValid;
    }

    return isValid;
  }

  inputChangedHandler(event, inputIndentifier) {
    const updatedContactForm = {
      ...this.state.contactForm,
    };
    const updatedFormElement = {
      ...updatedContactForm[inputIndentifier],
    };
    updatedFormElement.value = event.target.value;
    updatedFormElement.valid = this.checkValidity(
      updatedFormElement.value,
      updatedFormElement.validation
    );
    updatedFormElement.touched = true;
    updatedFormElement.onFocus = true;
    updatedContactForm[inputIndentifier] = updatedFormElement;

    let formIsValid = true;
    for (let inputIndentifier in updatedContactForm) {
      formIsValid = updatedContactForm[inputIndentifier].valid && formIsValid;
    }
    this.setState({
      contactForm: updatedContactForm,
      formIsValid: formIsValid,
    });
  }

  onFocusHandler(inputIndentifier) {
    const updatedContactForm = {
      ...this.state.contactForm,
    };
    const updatedFormElement = {
      ...updatedContactForm[inputIndentifier],
    };
    updatedFormElement.onFocus = true;
    updatedContactForm[inputIndentifier] = updatedFormElement;

    this.setState({
      contactForm: updatedContactForm,
    });
  }

  onBlurHandler(inputIndentifier) {
    const updatedContactForm = {
      ...this.state.contactForm,
    };
    const updatedFormElement = {
      ...updatedContactForm[inputIndentifier],
    };
    if (!updatedFormElement.value) {
      updatedFormElement.onFocus = false;
    }
    updatedContactForm[inputIndentifier] = updatedFormElement;

    this.setState({
      contactForm: updatedContactForm,
    });
  }

  modalCancelHandler = () => {
    this.setState({ modal: false });
    this.resetForm();
  };

  render() {
    const formElementsArray = [];

    for (let key in this.state.contactForm) {
      formElementsArray.push({
        id: key,
        config: this.state.contactForm[key],
      });
    }

    let form = (
      <form onSubmit={this.submitHandler}>
        {formElementsArray.map((formElement) => (
          <Input
            key={formElement.id}
            label={formElement.config.elementConfig.label}
            id={formElement.config.elementConfig.id}
            elementType={formElement.config.elementType}
            elementConfig={formElement.config.elementConfig}
            value={formElement.config.value}
            invalid={!formElement.config.valid}
            shouldValidate={formElement.config.validation}
            onFocused={() => this.onFocusHandler(formElement.id)}
            onBlured={() => this.onBlurHandler(formElement.id)}
            changed={(event) => this.inputChangedHandler(event, formElement.id)}
            focus={formElement.config.onFocus}
          />
        ))}
        <Button btnType="Success" disabled={!this.state.formIsValid}>
          Send
        </Button>
      </form>
    );

    return (
      <div>
        <Modal show={this.state.modal} modalClosed={this.modalCancelHandler}>
          <ModalInfo clicked={this.modalCancelHandler}/>
        </Modal>
        {form}
      </div>
    );
  }
}

export default ContactData;
