import React from 'react';
import classes from './Steps.module.css';

import First from '../../../assets/images/icons/step1.png';
import Second from '../../../assets/images/icons/step2.png';
import Third from '../../../assets/images/icons/step3.png';
import Fourth from '../../../assets/images/icons/step4.png';
import Fifth from '../../../assets/images/icons/step5.png';

const stepsList = [
  {
    icon: First,
    text: 'Send us a brief.',
  },
  {
    icon: Second,
    text: 'We’ll schedule a call with you to understand more about your goals.',
  },
  {
    icon: Third,
    text: 'We’ll send a proposal detailing type of traffic, DSP etc.',
  },
  {
    icon: Fourth,
    text: 'We’ll train your team, set tracking codes and prepare.',
  },
  {
    icon: Fifth,
    text: 'We’ll work with you until you reach the ROI and scale you need.',
  },
];

const steps = (props) => {
  const list = stepsList.map((step, index) => {
    return (
      <div key={index} className={classes.StepWrapper}>
        <div className={classes.StepIcon}>
          <img src={step.icon} alt={step.text}/>
        </div>
        <p>{step.text}</p>
      </div>
    );
  });

  return list;
};

export default steps;
