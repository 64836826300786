import React from 'react';
import classes from './AsideNav.module.css';
import Logo from '../../Logo/Logo';
import ResponsiveMenu from '../ResponsiveMenu/ResponsiveMenu';
import NavigationItem from '../NavigationItem/NavigationItem';
import styled from 'styled-components';
import Burger from '../../../assets/images/icons/burger.png';

const links = [
  {
    link: 'intro',
    name: 'intro',
    offset: 0,
    mobile0ffset: -60,
  },
  {
    link: 'who-we-are',
    name: 'who we are',
    offset: 0,
    mobile0ffset: -60,
  },
  {
    link: 'how-we-work',
    name: 'how we work',
    offset: 0,
    mobile0ffset: -60,
  },
  {
    link: 'industries',
    name: 'industries',
    offset: 0,
    mobile0ffset: -60,
  },
  {
    link: 'dps',
    name: 'dps',
    offset: 0,
    mobile0ffset: -60,
  },
  {
    link: 'formats',
    name: 'formats',
    offset: 0,
    mobile0ffset: -60,
  },
  {
    link: 'policy',
    name: 'policy',
    offset: 0,
    mobile0ffset: -60,
  },
  {
    link: 'volume-and-geos',
    name: 'Volume and geos',
    mobile0ffset: -60,
  },
  {
    link: 'the-next-steps',
    name: 'the next steps',
    offset: 0,
    mobile0ffset: -60,
  },
  {
    link: 'get-in-touch',
    name: 'Get in touch',
    offset: 0,
    mobile0ffset: -60,
  },
];

const Desktop = styled.div`
  display: block;
  @media (max-width: 1024px) {
    display: none;
  }
`;

const asideNav = (props) => {
  const menu = links.map((link, index) => {
    return (
      <NavigationItem key={index} link={link.link} offset={link.offset} mobile0ffset={link.mobile0ffset}>
        {link.name}
      </NavigationItem>
    );
  });

  return (
    <div className={classes.AsideNavigation}>
      <div className={classes.MainNavigation}>
        <nav>
          <Desktop>
            <Logo theme={props.theme} />
          </Desktop>
          <ResponsiveMenu
            menuOpenButton={
              <div>
                <img src={Burger} alt="open menu"/>
              </div>
            }
            menuCloseButton={
              <div>
                <img src={Burger} alt="close menu"/>
              </div>
            }
            changeMenuOn="1024px"
            largeMenuClassName="large-menu"
            smallMenuClassName="small-menu"
            menu={<ul>{menu}</ul>}
          />
        </nav>
      </div>
    </div>
  );
};

export default asideNav;
