import React from 'react';
import { Element } from 'react-scroll';
import Points from '../Points/Points';

import classes from '../Content.module.css';

const seventh = (props) => {
  return (
    <Element name="policy" className={classes.DarkTheme}>
      <div className={classes.ThemeWrapper}>
        <header>
          <h2>policy:</h2>
          <h3>
            Our experts will provide a solution for every policy-related issue
            you have, without resorting to unlawful or deceptive activities like
            blackhat. Some examples:
          </h3>
        </header>
        <div className="RowTop">
          <Points />
        </div>
      </div>
    </Element>
  );
};

export default seventh;
