import React from 'react';

import classes from './Points.module.css';


const items = [
  {
    id: 1,
    text: 'Running Crypto or Forex campaigns in major publishers'
  },
  {
    id: 2,
    text: 'Remarketing and lead generation in Gambling - Whitelisting advertisers and domains in major SSP (Google, Taboola etc.)'
  },
  {
    id: 3,
    text: 'Google or Facebook account/domain problems'
  },
];

const points = (props) => {
  const list = items.map((item, index) => {
    return (
      <div key={index} className={classes.PointWrapper}>
        <h2>{item.id}.</h2>
        <p>
          {item.text}
        </p> 
      </div>
    );
  });

  return list;
};

export default points;