import React from 'react';
import classes from './Contact.module.css';
import Mail from '../../../assets/images/icons/mail.png';
import Phone from '../../../assets/images/icons/phone.png';

const contact = (props) => (
  <nav className={classes.Contact}>
    <ul>
      <li>
        <img src={Phone} alt="bnikushev@zebra.bg" />
        <a>+359 88 6 224 366</a>
      </li>
      <li>
        <img src={Mail} alt="bnikushev@zebra.bg" />
        <a href="mailto:bnikushev@zebra.bg">bnikushev@zebra.bg</a>
      </li>
    </ul>
  </nav>
);

export default contact;
