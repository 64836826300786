import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import classes from './ResponsiveMenu.module.css';
import Logo from '../../Logo/Logo';
import Contact from '../Contact/Contact';

const SmallMenu = styled.div`
  display: none;
  @media (max-width: ${(props) => props.size}) {
    display: block;
  }
`;

const LargeMenu = styled.div`
  display: block;
  @media (max-width: ${(props) => props.size}) {
    display: none;
  }
`;

const MenuIcon = ({ onClick, icon }) => (
  <div role="button" onClick={onClick} className={classes.MobileButton}>
    {icon}
  </div>
);

class ResponsiveMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showMenu: false,
    };
  }

  componentDidMount() {
    window.addEventListener('scroll', this.closeMenu);
  }

  handleClick = () => {
    this.setState({ showMenu: !this.state.showMenu });
  };

  closeMenu = () => {
    this.setState({ showMenu: false });
  };

  render() {
    const {
      menu,
      largeMenuClassName,
      changeMenuOn,
      menuOpenButton,
      menuCloseButton,
    } = this.props;

    return (
      <div>
        <div className={classes.MobileHeader}>
          <div className={classes.MobileLogo}>
            <Logo theme={false} />
          </div>
          <SmallMenu size={changeMenuOn}>
            {!this.state.showMenu ? (
              <MenuIcon onClick={this.handleClick} icon={menuOpenButton} />
            ) : (
              <MenuIcon onClick={this.handleClick} icon={menuCloseButton} />
            )}
          </SmallMenu>
        </div>
        <SmallMenu size={changeMenuOn}>
          <div
            className={classes.MobileMenu}
            style={{
              top: this.state.showMenu ? '0' : '-100%',
            }}
          >
            {menu}
            <Contact />
          </div>
        </SmallMenu>
        <LargeMenu className={largeMenuClassName} size={changeMenuOn}>
          {menu}
        </LargeMenu>
      </div>
    );
  }
}

ResponsiveMenu.propTypes = {
  menu: PropTypes.node.isRequired,
  largeMenuClassName: PropTypes.string,
  smallMenuClassName: PropTypes.string,
  changeMenuOn: PropTypes.string.isRequired,
  menuOpenButton: PropTypes.node.isRequired,
  menuCloseButton: PropTypes.node.isRequired,
};

ResponsiveMenu.defaultProps = {
  largeMenuClassName: '',
  smallMenuClassName: '',
};

export default ResponsiveMenu;
