import React, { useState, useRef } from 'react';
import { useScrollPosition } from '@n8tb1t/use-scroll-position';
import styled from 'styled-components';

import Aside from '../Menus/AsideNav/AsideNav';

import First from '../Content/Section/First';
import Second from '../Content/Section/Second';
import Third from '../Content/Section/Third';
import Fourth from '../Content/Section/Fourth';
import Fifth from '../Content/Section/Fifth';
import Sixth from '../Content/Section/Sixth';
import Seventh from '../Content/Section/Seventh';
import Eighth from '../Content/Section/Eighth';
import Ninth from '../Content/Section/Ninth';
import Tenth from '../Content/Section/Tenth';
import classes from './Scroll.module.css';

const Wrapper = styled.div`
  overflow: hidden;
`;

const LightSection = styled.div`
  background: white;
  color: white;
  min-height: 100vh;
  display: flex;
  flex-wrap: wrap;
`;

const DarkSection = styled.div`
  background: black;
  color: white;
  min-height: 100vh;
  display: flex;
  flex-wrap: wrap;
  text-align: center;
  align-items: center;
  width: 100%;
`;

const PositionStore = () => {
  const section1Position = useRef({ x: 0, y: 0 });
  const section2Position = useRef({ x: 0, y: 0 });
  const section3Position = useRef({ x: 0, y: 0 });
  const section4Position = useRef({ x: 0, y: 0 });
  const section5Position = useRef({ x: 0, y: 0 });
  const section6Position = useRef({ x: 0, y: 0 });
  const section7Position = useRef({ x: 0, y: 0 });
  const section8Position = useRef({ x: 0, y: 0 });
  const section9Position = useRef({ x: 0, y: 0 });
  const section10Position = useRef({ x: 0, y: 0 });

  const getPos = (el, axis) => Math.round(el.current[axis]);

  const setPos = (el, pos) => {
    el.current = pos;
  };

  return {
    getSection1: () => getPos(section1Position, 'y'),
    getSection2: () => getPos(section2Position, 'y'),
    getSection3: () => getPos(section3Position, 'y'),
    getSection4: () => getPos(section4Position, 'y'),
    getSection5: () => getPos(section5Position, 'y'),
    getSection6: () => getPos(section6Position, 'y'),
    getSection7: () => getPos(section7Position, 'y'),
    getSection8: () => getPos(section8Position, 'y'),
    getSection9: () => getPos(section9Position, 'y'),
    getSection10: () => getPos(section10Position, 'y'),
    setSection1: (pos) => setPos(section1Position, pos),
    setSection2: (pos) => setPos(section2Position, pos),
    setSection3: (pos) => setPos(section3Position, pos),
    setSection4: (pos) => setPos(section4Position, pos),
    setSection5: (pos) => setPos(section5Position, pos),
    setSection6: (pos) => setPos(section6Position, pos),
    setSection7: (pos) => setPos(section7Position, pos),
    setSection8: (pos) => setPos(section8Position, pos),
    setSection9: (pos) => setPos(section9Position, pos),
    setSection10: (pos) => setPos(section10Position, pos),
  };
};

function Scroll() {
  const positionsStore = PositionStore();
  const [changeThemeOnScroll, setChangeThemeOnScroll] = useState(true);

  const section1 = useRef(null);
  const section2 = useRef(null);
  const section3 = useRef(null);
  const section4 = useRef(null);
  const section5 = useRef(null);
  const section6 = useRef(null);
  const section7 = useRef(null);
  const section8 = useRef(null);
  const section9 = useRef(null);
  const section10 = useRef(null);

  // Element scroll position

  useScrollPosition(
    ({ currPos }) => positionsStore.setSection2(currPos),
    [],
    section2,
    false,
    null
  );

  useScrollPosition(
    ({ currPos }) => positionsStore.setSection3(currPos),
    [],
    section3,
    false,
    null
  );

  useScrollPosition(
    ({ currPos }) => positionsStore.setSection4(currPos),
    [],
    section4,
    false,
    null
  );

  useScrollPosition(
    ({ currPos }) => positionsStore.setSection5(currPos),
    [],
    section5,
    false,
    null
  );

  useScrollPosition(
    ({ currPos }) => positionsStore.setSection6(currPos),
    [],
    section6,
    false,
    null
  );

  useScrollPosition(
    ({ currPos }) => positionsStore.setSection7(currPos),
    [],
    section7,
    false,
    null
  );

  useScrollPosition(
    ({ currPos }) => positionsStore.setSection8(currPos),
    [],
    section8,
    false,
    null
  );

  useScrollPosition(
    ({ currPos }) => positionsStore.setSection9(currPos),
    [],
    section9,
    false,
    null
  );

  useScrollPosition(
    ({ currPos }) => positionsStore.setSection10(currPos),
    [],
    section10,
    false,
    null
  );

  useScrollPosition(
    ({ prevPos, currPos }) => {
      let theme = false;
      
      if (positionsStore.getSection2() <= 100) {
        theme = true;
      }

      if (positionsStore.getSection3() <= 100) {
        theme = false;
      }

      if (positionsStore.getSection4() <= 100) {
        theme = true;
      }

      if (positionsStore.getSection5() <= 100) {
        theme = false;
      }

      if (positionsStore.getSection6() <= 100) {
        theme = true;
      }

      if (positionsStore.getSection7() <= 100) {
        theme = false;
      }

      if (positionsStore.getSection8() <= 100) {
        theme = true;
      }

      if (positionsStore.getSection9() <= 100) {
        theme = false;
      }

      if (positionsStore.getSection10() <= 100) {
        theme = true;
      }

      const isLight = theme;

      if (isLight !== changeThemeOnScroll) {
        setChangeThemeOnScroll(isLight);
      }
    },
    [changeThemeOnScroll]
  );

  return (
    <Wrapper>
      <div className="Content">
        <div className="Aside">
          <Aside theme={changeThemeOnScroll} />
        </div>
        <div className="Main">
          <div className="MainContent">
            <DarkSection ref={section1} className={classes.FirstSection}>
              <First />
            </DarkSection>
            <LightSection ref={section2} className={classes.SecondSection}>
              <Second />
            </LightSection>
            <DarkSection ref={section3} className={classes.ThirdSection}>
              <Third />
            </DarkSection>
            <LightSection ref={section4} className={classes.FourthSection}>
              <Fourth />
            </LightSection>
            <DarkSection ref={section5} className={classes.FifthSection}>
              <Fifth />
            </DarkSection>
            <LightSection ref={section6} className={classes.SixthSection}>
              <Sixth />
            </LightSection>
            <DarkSection ref={section7} className={classes.SeventhSection}>
              <Seventh />
            </DarkSection>
            <LightSection ref={section8} className={classes.EighthSection}>
              <Eighth />
            </LightSection>
            <DarkSection ref={section9} className={classes.NinthSection}>
              <Ninth />
            </DarkSection>
            <LightSection ref={section10} className={classes.TenthSection}>
              <Tenth />
            </LightSection>
          </div>
        </div>
      </div>
    </Wrapper>
  );
}

export default Scroll;
