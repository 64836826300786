import React from 'react';
import { Element,  Link } from 'react-scroll';
import classes from '../Content.module.css';

const first = (props) => {
  return (
    <Element name="intro" className={classes.DarkTheme}>
      <div className={classes.ThemeWrapper}>
        <h1>Traffic hub and media agency</h1>
        <Link
          to="get-in-touch"
          spy={false}
          smooth={true}
          duration={1000}
          offset={0}
          className="Button"
        >
          Contact us
        </Link>
      </div>
    </Element>
  );
};

export default first;
