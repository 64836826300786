import React from 'react';
import { Link } from 'react-scroll';

const navigationItem = (props) => (
  <li>
    <Link
      activeClass="Active"
      to={props.link}
      spy={true}
      smooth={true}
      duration={1000}
      offset={ window.innerWidth <= 1024 ? props.mobile0ffset : props.offset }
    >
      {props.children}
    </Link>
  </li>
);

export default navigationItem;
