import React from 'react';
import { Element } from 'react-scroll';
import Logos from '../../Logo/Logos';


import classes from '../Content.module.css';

const fifth = (props) => {
  return (
    <Element name="dps" className={classes.DarkTheme}>
      <div className={classes.ThemeWrapper}>
        <div>
          <header>
            <h2>dps:</h2>
            <h3>
              We can provide accounts and traffic in 15+ of the world’s biggest
              DPS, including:
            </h3>
          </header>
        </div>
        <div>
          <Logos />
        </div>
      </div>
    </Element>
  );
};

export default fifth;
