import React from 'react';
import { Parallax } from 'react-parallax';
import { Element } from 'react-scroll';
import Industries from '../../../assets/images/backgrounds/industries.png';
import styled from 'styled-components';

import classes from '../Content.module.css';

const Desktop = styled.div`
  display: block;
  @media (max-width: 1024px) {
    display: none;
  }
`;

const Mobile = styled.div`
  display: none;
  @media (max-width: 1024px) {
    display: block;
  }
`;

const fourth = (props) => {
  return (
    <Element name="industries" className={classes.LightTheme}>
      <div className={classes.ThemeWrapper}>
        <section className={classes.TextWrapper}>
          <header>
            <h2>Industries</h2>
          </header>
          <h3>
            Forget about blackhat or policy problems - our custom deals will
            allow any business to buy premium traffic and reach ROI regardless
            of the product. We’re particularly strong in:
          </h3>
          <ul>
            <li>Forex</li>
            <li>Crypto</li>
            <li>Gambling</li>
            <li>Ecommerce</li>
            <li>Adult</li>
            <li>Lead Generation</li>
            <li>Brand Awareness</li>
            <li>B2B</li>
          </ul>
        </section>
        <div className={classes.ImageWrapper}>
          <Parallax
            bgImage={Industries}
            strength={400}
            style={{ overflow: 'initial' }}
          >
            <Mobile style={{ height: '500px' }}></Mobile>
            <Desktop style={{ height: '120vh' }}></Desktop>
          </Parallax>
        </div>
      </div>
    </Element>
  );
};

export default fourth;
