import React from 'react';
import { Parallax } from 'react-parallax';
import { Element } from 'react-scroll';
import Volume from '../../../assets/images/backgrounds/man_double_expo.png';
import styled from 'styled-components';

import classes from '../Content.module.css';

const Desktop = styled.div`
  display: block;
  @media (max-width: 1024px) {
    display: none;
  }
`;

const Mobile = styled.div`
  display: none;
  @media (max-width: 1024px) {
    display: block;
  }
`;

const eighth = (props) => {
  return (
    <Element name="volume-and-geos" className={classes.LightTheme}>
      <div className={classes.ThemeWrapper}>
        <section className={classes.TextWrapper}>
          <header>
            <h2>
              <span>Volume</span>and GEOs:
            </h2>
            <h3>
              Ever wanted to buy Native traffic in Italy for example, just to
              discover there isn’t a lot available?
            </h3>
          </header>
          <p>
            No more the case with Zmatic – our custom PMP deals and array of DSP
            can provide all the volume you need in any country in the world.
            Just provide us with a brief and we’ll arrange a deal for you. We’re
            currently buying billions of impressions in 30+ countries using our
            network.
          </p>
        </section>
        <div className={classes.ImageWrapper}>
          <Parallax
            bgImage={Volume}
            strength={400}
            style={{ overflow: 'initial' }}
          >
            <Mobile style={{ height: '500px' }}></Mobile>
            <Desktop style={{ height: '80vh' }}></Desktop>
          </Parallax>
        </div>
      </div>
    </Element>
  );
};

export default eighth;
