import React, { Component } from 'react';

import classes from './Logos.module.css';

import Display from '../../assets/images/logos/display_360_logo.png';
import Adform from '../../assets/images/logos/adform.png';
import Basis from '../../assets/images/logos/basis_tm_logo.png';
import Verizon from '../../assets/images/logos/verizon-media_white.png';
import Search from '../../assets/images/logos/google-SA360.png';
import Adelphic from '../../assets/images/logos/adelphic.png';
import Smadex from '../../assets/images/logos/smadex.png';
import Platform from '../../assets/images/logos/platform_161.png';

const logoCol = [
  {
    firstImage: {
      src: Display,
      alt: 'alt one',
    },
    secondImage: {
      src: Search,
      alt: 'alt second',
    },
    showMobile: true,
  },
  {
    firstImage: {
      src: Adform,
      alt: 'alt one',
    },
    secondImage: {
      src: Adelphic,
      alt: 'alt second',
    },
    showMobile: true,
  },
  {
    firstImage: {
      src: Basis,
      alt: 'alt one',
    },
    secondImage: {
      src: Smadex,
      alt: 'alt second',
    },
    showMobile: false,
  },
  {
    firstImage: {
      src: Verizon,
      alt: 'alt one',
    },
    secondImage: {
      src: Platform,
      alt: 'alt second',
    },
    showMobile: false,
  },
];

class Logos extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showLogos: false,
      windowWidth: window.innerWidth,
    };
  }

  handleResize = () => {
    this.setState({
      windowWidth: window.innerWidth,
    });
  };

  componentDidMount() {
    this.handleResize();
    window.addEventListener('resize', this.handleResize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  handleClick = () => {
    this.setState({ showLogos: !this.state.showLogos });
  };

  render() {
    const columnMobile = logoCol.map((item, index) => {
      if (!item.showMobile) {
        return (
          <div key={index} className={classes.ColumnWrapper}>
            <div className={classes.Collapse}
              style={{
                display:
                  !item.showMobile !== this.state.showLogos ? 'none' : '',
              }}
            >
              <div className={classes.ImageWrapper}>
                <img src={item.firstImage.src} alt={item.firstImage.alt} />
              </div>
              <div className={classes.ImageWrapper}>
                <img src={item.secondImage.src} alt={item.secondImage.alt} />
              </div>
            </div>
          </div>
        );
      } else {
        return (
          <div key={index} className={classes.ColumnWrapper}>
            <div className={classes.ImageWrapper}>
              <img src={item.firstImage.src} alt={item.firstImage.alt} />
            </div>
            <div className={classes.ImageWrapper}>
              <img src={item.secondImage.src} alt={item.secondImage.alt} />
            </div>
          </div>
        );
      }
    });

    const columnDesktop = logoCol.map((item, index) => {
      return (
        <div key={index} className={classes.ColumnWrapper}>
          <div className={classes.ImageWrapper}>
            <img src={item.firstImage.src} alt={item.firstImage.alt} />
          </div>
          <div className={classes.ImageWrapper}>
            <img src={item.secondImage.src} alt={item.secondImage.alt} />
          </div>
        </div>
      );
    });

    let column = columnDesktop;
    if (this.state.windowWidth <= 769) {
      column = columnMobile;
    }

    return (
      <div>
        <div className={classes.RowLogos}>
          {column}
          <span onClick={this.handleClick} className={classes.ShowMore}>
            {!this.state.showLogos ? 'load more...' : null}
          </span>
        </div>
      </div>
    );
  }
}

export default Logos;
