import React from 'react';
import { Parallax, Background } from 'react-parallax';
import { Element } from 'react-scroll';
import Team from '../../../assets/images/backgrounds/team-test-test-test-test.png';
import styled from 'styled-components';

import classes from '../Content.module.css';

const Desktop = styled.div`
  display: block;
  @media (max-width: 1024px) {
    display: none;
  }
`;

const Mobile = styled.div`
  display: none;
  @media (max-width: 1024px) {
    display: block;
  }
`;

const second = (props) => {
  return (
    <Element name="who-we-are" className={classes.LightTheme}>
      <div className={classes.ThemeWrapper}>
        <section className={classes.TextWrapper}>
          <header>
            <h2><span>Who </span>are we?</h2>
          </header>
          <p>
            We’re one of the biggest traffic hubs in the world, providing
            advertisers in 30+ countries with premium traffic globally via our
            15+ DSP.
          </p>
          <p>
            We provide training, support, DSP access and PMP deals for every
            conceivable type of traffic, format, industry and GEO, enabling you
            to reach maximum ROI.
          </p>
        </section>
        <div className={classes.ImageWrapper}>
          <Parallax
            bgImage={Team}
            strength={400}
            style={{ overflow: 'initial' }}
          >
            <Mobile style={{ height: '500px' }}></Mobile>
            <Desktop style={{ height: '80vh' }}></Desktop>
          </Parallax>
        </div>
      </div>
    </Element>
  );
};

export default second;
