import React from 'react';
import { Parallax } from 'react-parallax';
import { Element } from 'react-scroll';
import Formats from '../../../assets/images/backgrounds/formats.png';
import styled from 'styled-components';

import classes from '../Content.module.css';

const Desktop = styled.div`
  display: block;
  @media (max-width: 1024px) {
    display: none;
  }
`;

const Mobile = styled.div`
  display: none;
  @media (max-width: 1024px) {
    display: block;
  }
`;

const sixth = (props) => {
  return (
    <Element name="formats" className={classes.LightTheme}>
      <div className={classes.ThemeWrapper}>
        <section className={classes.TextWrapper}>
          <header>
            <h2>formats</h2>
            <h3>We provide access to all types of traffic and formats.</h3>
          </header>
          <div className="RowTop">
            <ul className="TwoCol">
              <li>Display</li>
              <li>Native</li>
              <li>Mobile</li>
              <li>In-app</li>
              <li>Video</li>
              <li>Audio</li>
            </ul>
            <ul className="TwoCol">
              <li>Email</li>
              <li>Push</li>
              <li>Catv</li>
              <li>Rich media</li>
              <li>Search</li>
            </ul>
          </div>
        </section>
        <div className={classes.ImageWrapper}>
          <Parallax
            bgImage={Formats}
            strength={400}
            style={{ overflow: 'initial' }}
          >
            <Mobile style={{ height: '500px' }}></Mobile>
            <Desktop style={{ height: '80vh' }}></Desktop>
          </Parallax>
        </div>
      </div>
    </Element>
  );
};

export default sixth;
