import React from 'react';
import LogoLight from '../../assets/images/LogoLight.svg';
import LogoDark from '../../assets/images/LogoDark.svg';


const logo = (props) => {
  const themeLogo = props.theme ? LogoLight : LogoDark;

  return (
    <div>
      <img src={themeLogo} />
    </div>
  );
};

export default logo;
