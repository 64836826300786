import React from 'react';
import { Element } from 'react-scroll';
import classes from '../Content.module.css';

const third = (props) => {
  return (
    <Element name="how-we-work" className={classes.DarkTheme}>
      <section className={classes.ThemeWrapper}>
        <div>
          <header>
            <h2>How we work?</h2>
            <h3>
              We can provide traffic, support and training in two cooperation
              models:
            </h3>
          </header>
        </div>
        <div className="RowTop">
          <div className="TwoCol">
            <h4>Self-serve</h4>
            <p>
              Send us a brief and we’ll open accounts and provide PMP deals,
              training and support for your own media buying team. You’ll have
              full control over the campaigns and an experienced partner to help
              you run them.
            </p>
          </div>
          <div className="TwoCol">
            <h4>Managed service</h4>
            <p>
              Our own team of experienced certified media buyers will create and
              manage your campaigns and strive to reach your KPIs. Just lay back
              and relax while we make profits for you.
            </p>
          </div>
        </div>
      </section>
    </Element>
  );
};

export default third;
