import React from 'react';
import { Parallax } from 'react-parallax';
import { Element } from 'react-scroll';
import Contact from '../../../assets/images/backgrounds/together.png';
import ContactForm from '../FormData/FormData';
import styled from 'styled-components';

import classes from '../Content.module.css';

const Desktop = styled.div`
  display: block;
  @media (max-width: 1024px) {
    display: none;
  }
`;

const Mobile = styled.div`
  display: none;
  @media (max-width: 1024px) {
    display: block;
  }
`;

const tenth = (props) => {
  return (
    <Element name="get-in-touch" className={classes.LightTheme}>
      <div className={classes.ThemeWrapper}>
        <section className={classes.TextWrapper}>
          <header>
            <h2>get in touch</h2>
          </header>
          <ContactForm />
        </section>
        <div className={classes.ImageWrapper}>
          <Parallax
            bgImage={Contact}
            strength={500}
            style={{ overflow: 'initial' }}
          >
            <Mobile style={{ height: '500px' }}></Mobile>
            <Desktop style={{ height: '100vh' }}></Desktop>
          </Parallax>
        </div>
      </div>
    </Element>
  );
};

export default tenth;
