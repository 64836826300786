import React from 'react';
import Logo from '../../Logo/Logo';
import Close from '../../../assets/images/icons/close.png';

import classes from './ModalInfo.module.css';

const modalInfo = (props) => {
  return (
    <div className={classes.ModalInfo}>
      <div className={classes.CloseIcon} onClick={props.clicked}>
        <img src={Close} alt="close" />
      </div>
      <h3>
        Thank you for your inquiry! <br />
        We'll get back to you ASAP.
      </h3>
      <p>Our experts will reach you out soon.</p>
      <div className={classes.ModalLogo}>
        <Logo theme={true} />
      </div>
    </div>
  );
};

export default modalInfo;
