import React from 'react';
import { Element } from 'react-scroll';
import Steps from '../Steps/Steps';

import classes from '../Content.module.css';

const ninth = (props) => {
  return (
    <Element name="the-next-steps" className={classes.DarkTheme}>
      <div className={classes.ThemeWrapper}>
        <div>
          <header>
            <h2>What are the next steps?</h2>
          </header>
        </div>
        <div>
          <ul>
            <Steps />
          </ul>
        </div>
      </div>
    </Element>
  );
};

export default ninth;
